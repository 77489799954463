<template>
  <div
    className="flex items-center justify-between p-2 sticky top-0 z-50 bg-white lg:px-5 shadow-md w-auto overflow-hidden"
  >
    <div className="flex items-center">
      <h1 class="font-semibold whitespace-nowrap text-xl">撈取商店</h1>
    </div>

    <div className="flex  items-center ml-10 flex-grow">
      <div className="flex space-x-6 md:space-x-2">
        <router-link to="/table">
          <div
            className="flex items-center cursor-pointer md:px-10 sm:h-14 md:hover:bg-green-500 rounded-xl active:border-b-2 active:border-gray-500 group"
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/69/69524.png"
              className="h-5 text-gray-500 text-center sm:h-7 mx-auto
          group-hover:text-blue-500 "
            />
          </div>
        </router-link>
        <router-link to="/upload">
          <div
            className="flex items-center cursor-pointer md:px-10 sm:h-14 md:hover:bg-green-500 rounded-xl active:border-b-2 active:border-gray-500 group"
          >
            <img
              src="https://cdn3.iconfinder.com/data/icons/eightyshades/512/14_Add-512.png"
              className="h-5 text-gray-500 text-center sm:h-7 mx-auto
          group-hover:text-blue-500 "
            />
          </div>
        </router-link>
      </div>
    </div>

    <div className="flex items-center sm:space-x-2 justify-end">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
        className="rounded-full cursor-pointer h-10 w-10"
      />
      <p className=" font-semibold whitespace-nowrap pr-3">{{ user }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    user: String,
    auth: Boolean,
  },
};
</script>
