<template>
  <div
    class="bg-white w-11/12 p-5 rounded-3xl space-x-10 shadow-md flex items-center justify-evenly sm:justify-between"
  >
    <div class="flex space-x-2 items-center justify-evenly sm:justify-start">
      <img
        class="h-6 hidden sm:inline-flex"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Search_Icon.svg/1024px-Search_Icon.svg.png"
        alt=""
      />
      <div className="flex items-center space-x-1">
        <p class="hidden sm:inline-flex text-xl font-medium">店名:</p>
        <div className="flex ml-5 items-center rounded-full bg-gray-100 p-2">
          <form id="name-search" @submit.prevent="search(location, 1)">
            <input
              className=" md:inline-flex bg-transparent  items-center ml-2 outline-none"
              type="text"
              v-model="location"
              placeholder="Name"
            />
            <button hidden type="submit">Submit</button>
          </form>
        </div>
      </div>

      <div className="flex items-center space-x-1">
        <p class="hidden sm:inline-flex text-xl font-medium">中心點:</p>
        <div className="flex ml-5 items-center rounded-full bg-gray-100 p-2">
          <form
            id="originplace-search"
            @submit.prevent="search(originplace, 2)"
          >
            <input
              className=" md:inline-flex bg-transparent  items-center ml-2 outline-none"
              type="text"
              v-model="originplace"
              placeholder="中心點"
            />
            <button hidden type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>

    <Export :orders="orders" />
  </div>
</template>

<script>
import Export from "./Export.vue";
export default {
  name: "Search",
  components: { Export },
  props: ["search", "originplace", "location", "orders"],
  data() {
    return {};
  },
  methods: {},
};
</script>
