<template>
  <div
    class="h-screen overflow-y-auto flex flex-col items-center pt-10 bg-gray-100"
    v-if="!loading"
  >
    <Search
      :location="location"
      :search="search"
      :originplace="originplace"
      :orders="orders"
    />

    <div class="bg-white w-11/12 p-5 rounded-3xl mt-5 shadow-md">
      <table class="bg-white w-full">
        <thead>
          <tr class="text-sm font-semibold text-black">
            <td class="py-4 border-b border-gray-700">店名</td>
            <td class="py-4 border-b border-gray-700">電話</td>
            <td class="py-4 border-b border-gray-700">地址</td>
            <td class="py-4 border-b border-gray-700">評分</td>
            <td class="py-4 border-b border-gray-700 text-center">評分人數</td>
            <td class="py-4 border-b border-gray-700 text-center">中心點</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, i) in orders.slice((page - 1) * 8, page * 8)"
            class="text-sm text-gray-500 hover:bg-gray-100"
          >
            <Data
              :url="order.address"
              :name="order.name"
              :score="order.rating"
              :scoreamount="order.ratings_total"
              :originplace="order.origin_place"
              :phone="order.phone_number"
              :id="i"
            />
          </tr>
        </tbody>
      </table>
      <div class="flex gap-x-2 justify-center pt-8">
        <button
          @click="previousPage"
          class="flex justify-center items-center w-8 h-8"
        >
          <img
            src="https://cdn4.iconfinder.com/data/icons/geomicons/32/672373-chevron-left-512.png"
            class="w-6 h-6 to-gray-800 stroke-current hover:text-indigo-600"
          />
        </button>
        <button
          v-for="i in pagelimit.slice(
            0 + pageoverroll * 6,
            6 + pageoverroll * 6
          )"
          class="flex items-center justify-center w-8 h-8 font-medium rounded-full"
          :class="
            i === page
              ? 'bg-green-500 text-white'
              : 'text-gray-400 hover:text-indigo-600'
          "
          @click="changePage(i)"
        >
          {{ i }}
        </button>
        <button
          @click="nextPage"
          class="flex justify-center items-center w-8 h-8"
        >
          <img
            src="https://cdn4.iconfinder.com/data/icons/geomicons/32/672374-chevron-right-512.png"
            class="w-6 h-6 to-gray-800 stroke-current hover:text-indigo-600"
          />
        </button>
      </div>
    </div>
    <div class="p-10"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Data from "../components/Data.vue";
import axios from "axios";
import Search from "../components/Search.vue";

var orders = [
  {
    url: "none",
    name: "Cannot fetch database data",
    score: "none",
    scoreamount: "none",
    price: "none",
    originplace: "none",
    progress: "none",
    sales: "none",
    other: "none",
    phone: "none",
    scorelevel: "none",
  },
];
export default {
  name: "TableView",
  components: {
    Data,
    Search,
  },
  data() {
    return {
      page: 1,
      pagelimit: [],
      pageoverroll: 0,
      loading: true,
      orders,
      location: "",
      originplace: "",
      apiURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    nextPage() {
      if (this.page === this.pagelimit.length) return;
      if (this.page / 6 - this.pageoverroll === 1) {
        this.pageoverroll += 1;
      }
      this.page += 1;
    },
    previousPage() {
      if (this.page > 1) {
        this.page -= 1;
      }
      if (this.page / 6 - this.pageoverroll === 0) {
        this.pageoverroll -= 1;
      }
    },
    changePage(page) {
      this.page = page;
    },
    search(name, id) {
      if (id === 1) {
        if (name.length === 0) {
          const URL = `${this.apiURL}/api/data`;
          axios.get(URL).then((res) => {
            this.orders = res.data;
            console.log("get data");
            this.loading = false;
          });
          return;
        }
        const URL = `${this.apiURL}/api/data/name/${name}`;
        console.log(URL);
        axios.get(URL).then((res) => {
          this.orders = res.data;

          console.log("get name data");
        });
      } else if (id === 2) {
        if (name.length === 0) {
          const URL = `${this.apiURL}/api/data/origin_place/api/data`;
          axios.get(URL).then((res) => {
            this.orders = res.data;
            console.log("get data");

            this.loading = false;
          });
          return;
        }
        const URL = `${this.apiURL}/api/data/origin_place/${name}`;
        console.log(URL);
        axios.get(URL).then((res) => {
          this.orders = res.data;
          console.log("get origin place data");
        });
      }
    },
  },
  mounted() {
    this.pagelimit;
    console.log(this.apiURL);
    const URL = `${this.apiURL}/api/data`;
    axios.get(URL).then((res) => {
      console.log(res.data);
      this.orders = res.data;
      for (var i = 1; i <= this.orders.length / 8 + 1; i++) {
        this.pagelimit.push(i);
      }
      console.log("init data");
      this.loading = false;
    });
  },
};
</script>
