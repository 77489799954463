<template>
  <td class="py-4 border-b border-gray-700">
    {{ name }}
  </td>
  <td class="py-4 border-b border-gray-700">{{ phone }}</td>
  <td class="py-4 border-b border-gray-700">
    <a
      class="hover:text-blue-400"
      :href="`https://www.google.com.tw/maps/place/${url}`"
      target="_blank"
      >link</a
    >
  </td>
  <td class="py-4 border-b border-gray-700">{{ score }}</td>

  <td class="py-4 border-b border-gray-700 text-center">
    {{ scoreamount }}
  </td>
  <td class="py-4 border-b border-gray-700 text-center">{{ originplace }}</td>
</template>

<script>
export default {
  name: "Data",
  components: {},
  props: [
    "url",
    "name",
    "score",
    "scoreamount",
    "scorelevel",
    "price",
    "originplace",
    "progress",
    "sale",
    "other",
    "phone",
    "id",
  ],
  data() {
    return {};
  },
  methods: {},
};
</script>
