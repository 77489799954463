<template>
  <div class="flex items-center justify-center mt-40">
    <h1 class="text-7xl font-serif">Hello World !</h1>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  methods: {},
};
</script>
