<template>
  <div
    class="hidden md:inline-flex p-4 cursor-pointer rounded-full items-center border border-black bg-black hover:bg-slate-500"
    @click.prevent="this.export"
  >
    <p class="text-sm font-medium text-white">Download Excel</p>
  </div>
</template>
<script>
var XLSX = require("xlsx");
export default {
  name: "Export",
  props: ["orders"],
  data() {
    return {};
  },
  methods: {
    export() {
      const data = this.orders;
      const datetime = new Date();
      const month = datetime.getMonth() + 1;
      var date =
        datetime.getFullYear() +
        "年" +
        month +
        "月" +
        datetime.getDate().toString() +
        "日";
      const wsname = date + "匯出資料";
      var newWorkBook = XLSX.utils.book_new();
      var newWorkSheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(newWorkBook, newWorkSheet, wsname);

      XLSX.writeFile(newWorkBook, wsname + ".xlsx");
    },
  },
};
</script>
