<template>
  <header-vue :user="user" />
  <router-view />
</template>

<script>
import HeaderVue from "./components/Header.vue";
export default {
  name: "home",
  components: {
    HeaderVue,
  },
  data() {
    return {
      user: "User",
    };
  },
};
</script>
